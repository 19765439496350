import { useRef, useState } from 'react';
import {
  Col, Row, Image, Form, Button,
} from 'react-bootstrap';
import { Link } from 'gatsby';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import IMG_E8932 from '../images/subscribe/IMG_E8932.jpg';
import { subscribe } from '../api/SubscribeAPI';

function Subscribe(props) {
  const { location } = props;
  const searchParams = new URLSearchParams(location.search);

  const [validated, setValidated] = useState(false);
  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const refIsSubmitting = useRef(false);

  const handleEmailChange = (evt) => {
    setEmail(evt.target.value);
  };

  const handleSubmit = async (evt) => {
    if (refIsSubmitting.current) {
      return true;
    }

    const form = evt.currentTarget;
    evt.preventDefault();
    evt.stopPropagation();
    setValidated(true);

    if (form.checkValidity()) {
      refIsSubmitting.current = true;
      setIsSubmitting(true);
      await subscribe({
        email,
        fromQ: searchParams.has('from_q')
          ? searchParams.get('from_q')
          : 'subscribe',
        event: searchParams.has('event') ? searchParams.get('event') : '',
      })
        .then(() => {
          toast.dark('Thanks for your subscribe! 😀');
        })
        .catch(() => {
          toast.dark(
            "Oops! There's something wrong, please try it later. If it's still not working, please let us know. 🙏",
          );
        })
        .then(() => {
          refIsSubmitting.current = false;
          setIsSubmitting(false);
        });
    }

    return true;
  };

  return (
    <Layout>
      <SEO title="Subscribe" />
      <Row className="text-center container mx-auto py-5">
        <Col>
          <Row>
            <Col>
              <h1>Subscribe</h1>
            </Col>
          </Row>
          <Row>
            <Col>
              <h5>Subscribe to our newsletter to receive updates on.</h5>
            </Col>
          </Row>
          <Row>
            <Col xs lg="8" className="mx-auto">
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Row>
                  <Form.Group as={Col} controlId="validationCustom01">
                    <Form.Control
                      required
                      type="email"
                      placeholder="Your Email"
                      disabled={isSubmitting}
                      onChange={handleEmailChange}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter your email.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>
                <div className="d-grid mt-2">
                  <Button
                    variant="secondary"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Subscribe
                  </Button>
                </div>
              </Form>
              <p className="small text-muted text-start mt-1">
                By submitting this form, you are agreeing to receive Waltty
                Playing Card Studio messages. You are always free to easily
                unsubscribe or customize your email preferences at any time. If
                you have any further questions, please
                {' '}
                <Link to="/contact/">contact us</Link>
                .
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs lg="8" className="mx-auto">
              <Image
                fluid
                src={IMG_E8932}
                alt="Aura playing cards by Waltty playing card studio"
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Layout>
  );
}

Subscribe.defaultProps = {
  location: {},
};

Subscribe.propTypes = {
  location: PropTypes.shape({ search: PropTypes.shape({}) }),
};

export default Subscribe;
